// feature names being used in rollouts
export const FEATURE_INTEGRATED_PROFILE = 'REFERRALS_WORK_FLOW';

export const BOOKINGS_APP_URL = 'https://staging.bookings.healthshare.com.au/';

// event categories
export const EVENT_INTEGRATED_PROFILE = 'integrated-profile';
export const FIND_A_PROVIDER = 'Find a provider';
export const INTEGRATED_REFERRALS_PROMOTION = 'integrated-referrals-promotion';
export const BOOKINGS_PRACTICE_ONBOARDING = 'bookings-practice-onboarding';

// local storage
export const INTEGRATED_PROFILE_MESSAGE_DISABLED =
    'integrated-profile-message-disabled';
export const SEARCH_EXPLAINER_PRACTICE_DISABLED =
    'search-explainer-practice-disabled';
export const SEARCH_EXPLAINER_DISABLED = 'search-explainer-disabled';
export const SEARCH_QUERY = 'search-query';
export const SEARCH_LOCALITY = 'search-locality';
export const SEARCH_STATE = 'search-state';

// cookies
export const HF_COOKIE = 'selected_health_fund';

// session storage
export const PROFILE_SMS_MOBILE = 'integrated-profile-sms';
export const IS_SWITCH_ON = 'is-integrated-profile-sms-switch-on';
export const SHOW_REFERRAL_BTN = 'show-referral-buttons';

// page types
export const PAGE_TYPE_HOSPITAL = 'page-type-hospital';
export const PAGE_TYPE_PRACTITIONER = 'page-type-practitioner';
export const PAGE_TYPE_PRACTICE_GROUP = 'page-type-practice-group';
export const PAGE_TYPE_PRACTICE_LOCATION = 'page-type-practice-location';
export const PAGE_DATA = {
    [PAGE_TYPE_PRACTITIONER]: {
        'page_title': 'profile page',
        'type_of_item': "Practitioner's profile",
    },
    [PAGE_TYPE_PRACTICE_GROUP]: {
        'page_title': 'practice group page',
        'type_of_item': 'Practice group',
    },
    [PAGE_TYPE_PRACTICE_LOCATION]: {
        'page_title': 'practice location page',
        'type_of_item': 'Practice location',
    },
};

// directory results type
export const PRAC_LOC_TYPE = 'practice-location';

// contact modal

export const NEARBY_HOSPITAL_CONTACT_TYPE = 'nearby-hospitals';
export const HOSPITAL_CONTACT_TYPE = 'hospital';
export const PROFILE_CONTACT_TYPE = 'profile';
export const PRACTICE_GRP_CONTACT_TYPE = 'group';
export const PRACTICE_LOC_CONTACT_TYPE = 'location';

// badget type
export const HICAPS = 'HICAPS';
export const TELEHEALTH = 'Offers Telehealth';

// boolean consts
export const TRUE_STR = 'True';

// keyboard navigation
export const ARROW_DOWN = 'ArrowDown';
export const ARROW_UP = 'ArrowUp';
export const ENTER = 'Enter';
export const ESC = 'Esc';
export const ESCAPE = 'Escape';
export const TAB = 'Tab';

// tooltip text
export const CLAIM_YOUR_PROFILE = 'claim-your-profile'
export const TOOLTIP_TELEHEALTH_TEXT =
    'This practitioner offers phone and/or video consultations.';
export const TOOLTIP_HICAPS_TEXT =
    'Claim on-the-spot with HICAPS: use your health fund/Medicare card when paying for the service to receive your rebate instantly. You will only need to pay the difference between the service fee and the rebate.';
export const TOOLTIP_FEATURED_TEXT =
    'These practices and practitioners have a commercial arrangement with HealthShare.';

// gaSelectors
export const ADDRESS_LINK = 'address-link';
export const HOSPITAL_AFFILIATIONS_CARD_LINK =
    'hospital-affiliations-card-link';
export const IN_HOSPITAL_NETWORK_FILTER = 'in-hospital-network-filter';
export const CALL_NOW_MODAL_BANNER_ADS = 'directory-search-result-card-link';
export const BOOK_NOW_BTN_BKG_PROD_PRIMARY =
    'book-now-button-bookings-product-primary';
export const BOOK_NOW_BTN_MH1_PRIMARY = 'book-now-button-mh1-primary';
export const BOOK_NOW_BTN_MH1_PRIMARY_DISABLED =
    'book-now-button-mh1-primary-disabled';
export const BOOK_NOW_BTN_MH1_LOCATION = 'choose-a-location-mh1';
export const BOOK_NOW_BTN_REQ_PRIMARY =
    'book-now-button-request-an-appointment-primary';
export const BOOK_NOW_BTN_REQ_SECONDARY =
    'book-now-button-request-an-appointment-secondary';
export const CALL_NOW_BTN_PRIMARY = 'call-now-button-primary';
export const CALL_NOW_BTN_SECONDARY = 'call-now-button-secondary';
export const FAQ = 'faq';
export const SELECT_HEALTH_FUND_BTN = 'select-health-fund-button';
export const ADD_TO_FAVOURITES_BTN = 'add-to-favourites-button';
export const REMOVE_FROM_FAVOURITES_BTN = 'remove-from-favourites-button';
export const WRITE_REFERRAL_BTN = 'write-referral-button';
export const COPY_REFERRAL_SELECT_PRAC_POSITION_BTN =
    'copy-referral-details-select-practice-position-button';
export const COPY_REFERRAL_BTN = 'copy-referral-details-button';
export const SEE_PRACTITIONER_QUICKLY_BTN =
    'see-a-practitioner-quickly-button';
export const SEARCH_BTN = 'search-button';
export const DIRECTORY_SEARCH_RESULT_CARD_LINK =
    'directory-search-result-card-link';
export const SEND_PROFILE_BTN = 'send-profile-button';
export const SEND_PROFILE_CHECKBOX = 'send-profile-checkbox';
export const POPULAR_SPECIALTY_RESULT_LINK = 'popular-specialty-result-link';
export const FILTER_LANGUAGE = 'languages-filter';
export const FILTER_GENDER = 'gender-filter';
export const FILTER_TELEHEALTH = 'offers-telehealth-filter';
export const HF_FEES_MODULE = 'phi-fees-module';
export const REGISTRATION_NUMBERS = 'registration-numbers';
export const QUESTIONS_AND_ANSWERS_SELECTOR = 'selector';
export const REVIEWS = 'reviews';
export const SEE_OUT_OF_POCKET_COSTS = 'see-out-of-pocket-costs';
export const REPORT_AN_ISSUE = 'report-an-issue';

// gaObjectType
// cards
export const ABOUT_SECTION = 'about-section'
export const BANNER_AD_CARD = 'banner-ad-card';
export const CALL_NOW_BUTTON_BANNER_ADS = 'banner-ad-card profile-card';
export const HOSPITAL_CARD = 'hospital-card';
export const PRACTICE_GROUP_CARD = 'practice-group-card';
export const PRACTICE_LOCATION_CARD = 'practice-location-card';
export const PROFILE_CARD = 'profile-card';
// sections
export const HEADER_SECTION = 'header-section';
export const HOSPITAL_NETWORK_FILTER = 'header-section';
export const FEES_SECTION = 'fees-section';
export const LOCATION_SECTION = 'location-section';
export const PRACTICE_FAQ = 'faq-section';
export const PRACTITIONERS_SECTION = 'practitioners-section';
export const RELATED_INFO_SECTION = 'related-info-section';
export const QUESTIONS_AND_ANSWERS_SECTION = 'qna-section';
export const REVIEWS_SECTION = 'reviews-section';
export const OVERVIEW_SECTION = 'overview-section';

//dataTestId
export const IN_HOSPITAL_NETWORK_FILTER_TEST_ID = 'in-hospital-network-filter';
export const COPY_REFERRAL_BTN_TEST_ID = 'cta-copy-referral-details';
export const WRITE_REFERRAL_BTN_TEST_ID = 'cta-write-referral';
export const BOOK_NOW_BTN_CUSTOM_URL_TEST_ID = 'cta-bookings-custom-url';
export const BOOK_NOW_BTN_MH1 = 'cta-bookings-mh1';
export const HEALTH_FUND_SELECTOR = 'health-fund-selector';
export const CTA_REQUEST_APPT = 'cta-request-appointment-btn';
export const CTA_CALL_NOW = 'cta-call-now-btn';

//Avatar size
export const AVATAR_SIZE_SMALL = 'small';
export const AVATAR_SIZE_LARGE = 'large';
export const AVATAR_SIZE_DEFAULT = 'default';
export const AVATAR_SIZE_HUGE = 'huge';
export const AVATAR_SIZE_LARGER = 'larger';
export const AVATAR_SIZE_MEDIUM = 'medium';

// paramstore
export const BP = 'bp';
export const BP_FHIR = 'bp_fhir';
export const MD = 'md';
export const REFERRALS = 'referrals';
export const SITE = 'site';
export const HCP = 'hcp';
export const ZM = 'zm';

export const SEND_SMS_TEXT = `Send profile to patient's mobile`;
export const FREE_SMS_TEXT =
    'This service is free and comes from a no-reply number';
export const FREE_SMS_BP_FHIR_TEXT = 'Free SMS from a no-reply number';

// environments

export const PRODUCTION = 'prod';

// BC_ERROR_CODES

export const BC_ERROR_CODES = [
    'NO_PATIENT_SELECTED',
    'PATIENT_DATA_EMPTY',
    'INTERNAL_SERVER_ERROR',
    'RATE_LIMIT_EXCEEDED',
];
//claim your profile text
export const CLAIM_YOUR_PROFILE_TEXT = 'Is this you? - Claim your profile'
// buttons
export const PRIMARY = 'primary';

export const DESKTOP_MIN_WIDTH = 1023;

export const ORIENTATION_TYPE_LANDSCAPE = 'landscape';
export const ORIENTATION_TYPE_PORTRAIT = 'portrait';
